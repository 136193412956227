import * as React from "react"
import "../assets/css/error.css"
import { Link } from "gatsby"
import Seo from "../components/seo"

const NotFoundPage = () => (
    <div className="error">
      <Seo title="404" />
      <h2>Oops! Page not found.</h2>
      <h1>404</h1>
      <p>We can't find the page you're looking for.</p>
      <Link to="/" className="link_error">Go back home</Link>
    </div>
)

export default NotFoundPage
